.NewletterItem {
    margin: 20px;
    margin-left: 25px;
    margin-right: 25px;
    padding: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}
.PostHeader{
    display: flex;
}
.PostTopLeft {
    width: 80%;
    display: flex;
    justify-content: start;
}
.DeletePost{
    padding-right: 10px;
    cursor: pointer;
    color: var(--color-pink);

}
.DeletePost svg{
    width: 18px;
    height:18px;
}
.PostTopRight{
    width: 20%;
    display: flex;
    justify-content: end;
}

.PublisherImageContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%; 
    overflow: hidden;  
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--color-pink); */
    
}
.HeartBlackOutlined svg{
    color: grey !important;
}

.PublisherImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.PublisherNameAndDate{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
}
.PublishDate{
    font-size: 12px;
    color: #7D7D7D;
}
.NewletterItemCol{
    text-align: start;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

}
.NewsletterImage{
    /* max-height: 400px; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.NewsletterImage img{
    max-width: 600px;
}
.NewsletterLikes{
    display: flex;
    align-items: center;
    padding-left: 25px;
}
.NewsletterLikes svg{
    width: 30px;
    height: 30px;
    color: red;
    margin-right: 5px;
}
.CircleInPopUp{
    background-color: var(--color-blue);
    color: white;
    padding: 10px;
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
}
.CircleInPopUp input{
    display: none;
}
.CircleInPopUp svg{
    width: 30px;
    height: 30px;
}
.CircleInPopUpContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.NewsletterDescription2{
    margin-top: 10px;
    margin-bottom: 10px;
}
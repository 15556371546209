.ClassesAllToAssignContainer{
    max-height: 900px;
}
.ClassesToAssignContainer{
    display: flex;
    overflow-x: scroll;
}
.ClassCardToAssign{
    padding: 10px;
    margin: 10px;
    min-width: 250px;
    min-height: 400px;
    background-color: var(--color-blue);
    border-radius: 25px;
    max-width: 250px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ClassCardName{
    color: white;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid;
    padding: 10px;
}
.SubjectToGrab{
    cursor: grab;
}

.EditSubjectsContainer{
    position: absolute;
    top: 10px;
    right:20px;
    width: 30px;
    height: 30px;
    padding: 20px;
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;
    color: black;
    transition: all 0.2s ease-in-out;
}
.EditSubjectsContainer:hover{
    background-color: var(--color-blue);
    color: white;
}
.Clickable{
    cursor: pointer;
}
.slider-container {
    border: 1px solid var(--color-pink);
    border-radius: 10px;
    /* padding: 10px; */
    background-color: var(--color-light-blue);
    text-align: center;
    /* max-width: 100%; */
    overflow: hidden;
  }
  
  .SecondSliderSection .react-multi-carousel-list{
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
  .SecondSliderSection>.Container{
    padding-left: 25px;
    padding-right: 25px;
    margin: 0;
    width: 100% !important;
  }
  .SliderHr{
    height: 1px;
    background-color: var(--color-pink);
    padding: 0;
  }
  .class-item {
    display: inline-block;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
  }
  .ActiveClassItem{
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    font-weight: 700;
    color: var(--color-pink);
  }
  
  .info {
    /* margin-top:2px; */
    text-align: start;
    padding: 5px;
    padding-left: 20px;
    font-size: 10px;
    font-weight: 700;
  }
  .CustomPrevBtn{
    position: absolute;
    left: 5px;
    padding: 5px;
    top: 3px;
    padding-bottom: 0;
    overflow: hidden;
    background-color: #F2FAFE !important;
    border: none;
    outline: none;
    
  }
  .CustomNextBtn{
    position: absolute;
    padding: 5px;
    overflow: hidden;
    padding-bottom: 0;
    top: 3px;
    right: 5px;
    background-color: #F2FAFE !important;
    border: none;
    outline: none;
  }
  .CustomNextBtn:focus,
  .CustomPrevBtn:focus{
    border: none !important;
    outline: none !important;
  }
  .CustomNextBtn:active,
  .CustomPrevBtn:active{
    border: none !important;
    outline: none !important;
  }
  .WithSelectAllNext{
    top: 4%;
    right: 0px;
    height: 100%;
    margin: 0 ;
  }
  .WithSelectAllPrev{
    top: 4%;
    left: 0px;
    height: 100%;
    margin: 0 ;

  }
  .ClassItemWithSelectAll{
    font-size: 14px;
  }

  .ClassInfo span{
    display: flex !important;
  }

  .EditClassBtn{
    margin-left: 10px;
    cursor: pointer;
  }
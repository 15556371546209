.HeadLeftItem{
    margin-top: 5px;
    margin-bottom: 5px;
    width: max-content;
    display: flex;
    width: 150px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-green);
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 30px;
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.HeadRightItem{    
    margin-top: 5px;
    margin-bottom: 5px;
    width: max-content;
    background-color: var(--color-blue);
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 50%;
    color: white;
    margin-right: 20px;
}
.HeadRightCol{
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
}
.HeadContainer{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.CirclePlus{
    cursor: pointer;
}
.ManageClassesCompnent{
    margin-bottom: 30px;
}

.SubjectsContainer{
    background-color: var(--color-light-blue);
    margin-left: 25px;
    margin-right: 25px;
    padding: 20px;
    border-radius: 20px;
    max-height: 180px;
    overflow: hidden;
    overflow-y: scroll;

}
.SubjectsContainer::-webkit-scrollbar {
    width:2px;
    background-color: #ffffff;
  }
  
  .SubjectsContainer::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .SubjectsContainer::-webkit-scrollbar-thumb {
    background-color: var(--color-pink);
  }
  
  
  .SubjectsContainer::-moz-scrollbar {
    width:2px;
    background-color: #ffffff;
  }
  
  .SubjectsContainer::-moz-scrollbar-track {
    border-radius: 10px;
  }
  
  .SubjectsContainer::-ms-scrollbar {
    width:2px;
    background-color: white;
  }
  
  .SubjectsContainer::-ms-scrollbar-track {
    border-radius: 10px;
  }
  
  .SubjectsContainer::-ms-scrollbar-thumb {
    /* border-radius: 10px; */
    background-color:var(--color-pink);
  }
  
.SubjectsContainer>.row{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}
.SubjectItem{
    background-color: var(--color-pink);
    border-radius: 30px;
    margin: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    max-height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white;
    position: relative;
}
.SubjectItem .RemoveSubject{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items:center ;
  left: 0;
  background-color: #ED6D91;
  color: white;
  bottom: -100%;
  transition: all 0.1s linear;
}
.SubjectItem:hover .RemoveSubject{
  bottom: 0%;

}

.SubjectItem .RemoveSubject{
  cursor: pointer;
}
.ClassesMeals{
  padding-right: 110px !important;
  position: relative;
}
.SelectAllClasses{
  position: absolute;
  right: 30px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-pink);
  border-radius: 10px;
  font-size: 14px;
  padding: 5px;
}
.existing-subjects{
  display: flex;
  justify-content: space-evenly;

}
.existing-subjects .dropdown-container{
  width: 90%;
}
.AddNew{
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  
}
.existing-subjects .dropdown-list{
  max-height: 75px !important;
  overflow-y:scroll ;
}
.existing-subjects .dropdown-list{
  overflow: auto; 
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}
.existing-subjects .dropdown-list::-webkit-scrollbar {
  display: none; 
}

.CalenderCol{
  position: relative;
}
.Days{
  position: absolute;
  top: 41px;
  right: 15px;
  background: #009fe3;
  color:rgb(216 214 214 / 77%);
  padding: 10px;
  z-index: 20;  
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
}
.ActiveDays{
  display: flex;
}
.DayItem{
  padding: 2px;
}
.DayItem{
  display: flex;
  justify-content: space-between;
}
.DayInnerItem{
  margin: 5px;
}
.DayInnerItem{
  cursor: pointer;
}
.SelectedDateItem{
  color: white;
  font-weight: 500;
  
}
.ClassCardContainerRow{
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
}
.ClassCard{
  background-color: transparent ;
  color: black;
  border-radius: 25px;
  padding: 5px;
  border: 1px solid #ED6D91;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  max-width: 120px !important;
  padding: 5px;
  height: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.ClassCard:hover{
  background-color: #ED6D91;
  color: white;
}
.ActiveClass{
  background-color: #ED6D91;
  color: white;
}
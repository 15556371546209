.dropdown-container {
    position: relative;
    /* width: 200px; */
    font-family: Arial, sans-serif;
}

.dropdown-header {
    padding: 10px 15px !important;
    border: 2px solid var(--color-pink);
    border-radius: 30px;
    color: var(--color-pink) !important;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    background-color: white;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    height: 35px;
}

.dropdown-header:hover {
    background-color: #ffe6f0; 
}

.dropdown-arrow {
    border: solid var(--color-pink);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    transition: transform 0.3s;
    transform: rotate(-45deg);
}

.dropdown-arrow.open {
    transform: rotate(45deg);
}

.dropdown-list {
    position: absolute;
    top: 80%;
    left: 2.5%;
    width: 95%;
    right: 0;
    border: 2px solid  var(--color-pink);
    border-radius: 30px; 
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 5px;
    background-color: var(--color-pink);
    z-index: 1000;
    overflow: hidden;
}

.dropdown-item {
    padding: 5px 10px !important;
    cursor: pointer;
    color:white !important;
    text-align: center !important;

}




.dropdown-item:hover {
    background-color: #ffe6f0 !important; 
    color:var(--color-pink) !important;

}
.dropdown2{
    max-height: 150px;
    overflow-y: scroll;
}
.dropdown2::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

.dropdown2 {
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
.dropdown-header2{
    border-radius: 10px !important;
}
.ClassTeacherDropDown{
    border-radius: 30px !important;
}
.daydropdown{
    max-height: 75px;
}

.dropdown-list ,
.RoleDrobDownList{
    max-height: 100px;
    overflow-y: scroll;
}

.dropdown-list::-webkit-scrollbar ,
.RoleDrobDownList::-webkit-scrollbar {
    width: 0;
    height: 0;
}
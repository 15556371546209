.TableContainer {
    width: 100%;
}

.TableContainer table {
    width: 100%;
    border-spacing: 0;
}

.TableContainer td {
    background-color: var(--color-pink) !important;
    color: white !important;
    padding: 10px;
    border: none;
    width: calc(100%/7);
}
.TableContainer td input[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid white;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
}

.TableContainer input[type="checkbox"]::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-pink); /* Background color before checked */
    border-radius: 4px;
}

.TableContainer input[type="checkbox"]:checked::before {
    background-color: var(--color-pink); /* Background color when checked */
}

.TableContainer input[type="checkbox"]:checked::after {
    content: "✓";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 10px;
    font-weight: 900;
}
.TableContainer tr {
    margin-bottom: 10px; /* Adjust the value to increase/decrease the margin */
    display: table-row; /* Resetting the display property */
}

.TableContainer tr td:nth-child(1) {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

.TableContainerHistoryPayment tr td:nth-child(7) {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}


/* New styles to add margin between rows */
.TableContainer tr {
    display: block;
    margin-bottom: 10px; /* Adjust the value to increase/decrease the margin */
}

.TableContainer tr td {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    position: relative;
}
.NamePayment{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}

.SelectAll{
    display: flex;
    justify-content: end;
    align-items: center;
}
.InputSelectAll{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.InputSelectAll{
    font-size: 14px;
    padding: 5px;
}
.Payed svg{
    color: var(--color-green);
}
.SearchPayment{
    margin-right: 5px;
    width: 50%;
}
.TotalPayContainer{
    border: 1px solid var(--color-pink);
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    padding: 10px;
    padding-left: 25px;
    border-radius: 15px;
    text-align: start;

}
.Paied{
    color: green !important;

}
.NotPaied{
    color: white !important;

}
.PaymentHistoryTableContainer{
    overflow: visible !important;
    max-height: 500px;
}
.PaymentHistoryCol{
    padding: 0 !important;
}
.PaymentHistoryCol:nth-child(1){
    padding-left: 5px !important;
}
.PaymentHistoryCol:nth-child(8){
    padding-right: 5px !important;
}
.PaymentStatus{
    cursor: pointer;
    text-decoration: underline;
}
.pending{
    color: orange  !important;
}
.review{
    color: var(--color-pink)  !important;

}
.accepted{
    color: green  !important;

}
.PaymentImage img{
    border-radius: 25px;
}
@media (max-width:1062px) {
    .TableContainer .NamePayment::after {
        content: attr(data-content);
        position: absolute;
        top: -100%;
        left: 0;
        border-radius: 15px;
        padding: 5px;
        background-color: var(--color-pink);
    }
    
    .TableContainer .NamePayment:hover::after ,
    .TableContainer .NamePayment:hover td{
        top: 2px;
        overflow: visible;
        z-index: 10;
        padding: 5px;
      

    }
    td:hover  {
        overflow: visible;
    }
}
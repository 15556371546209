.ChooseAdminRole{
    margin-top: 10px;
}
.DeleteAdmin{
    cursor: pointer;
}
.AdminsTableContainer{
    max-height: 700px;
}
.pointer{
    cursor: pointer;
}
.ParentRequestSection {
    margin-left: 10px;
    margin-right: 10px;
}

.ParentRequestRow {
    border: 2px solid var(--color-pink) !important;
    border-radius: 10px;
    background-color: var(--color-light-blue);
}

.OpenRequestCol {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.ClosedRequestCol {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
}

.SelectedRequest {
    color: var(--color-pink);
    background-color: #daeffa;
    border-radius: 10px;
}

.ParentRequestItem {
    margin: 20px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

.ParentRequestItemPopUp {
    margin: 0px !important;
}

.ReplyParentRequest {
    background-color: var(--color-light-blue);
}

.ParentRequestItemReplied {
    background-color: var(--color-light-blue);
}

.ClosedRequestItemContainer .ParentRequestItem {
    margin: 0;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 0;
}

.ClosedRequestItemContainer {
    margin-top: 25px;
    margin-bottom: 25px;
}

.ReplierHeader {
    width: 100%;
}

.ChatTopArea {
    border-bottom: 2px solid #8080803d;
}

.ChatTopArea h6 {
    margin: 0 !important;
}

.ChooseParent {
    height: 300px !important;
    max-height: 400px !important;
    overflow-y: scroll;
}
.ChooseParent {
    scrollbar-width: thin;
    scrollbar-color: var(--color-pink) transparent; 
    padding: 10px;
    padding-top: 0;
}


.ParentCreateChat {
    border: 1px solid var(--color-pink);
    border-radius: 15px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    text-align: start;
    cursor: pointer;
    padding: 10px;
    transition: all 0.2s ease-in-out;
}

.ParentCreateChat:hover {
    background-color: var(--color-pink);
    color: white;
}

.ParentCreateChatHeader {
    position: relative;
    width: 100%;
}

.ExitPopUp {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 0px;
}
.ParentCreateChat {
    border: 1px solid var(--color-pink);
    border-radius: 15px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    text-align: start;
    cursor: pointer;
    padding: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 300px; /* Set a height to allow scrolling */
}

/* Custom scrollbar styles for WebKit browsers (Chrome, Edge, Safari) */
.ChooseParent::-webkit-scrollbar {
    width: 2px; /* Set scrollbar width */
}

.ChooseParent::-webkit-scrollbar-thumb {
    background-color: pink; /* Set thumb color */
    border-radius: 10px; /* Make thumb rounded */
}

.ChooseParent::-webkit-scrollbar-track {
    background: transparent; /* Track background color */
}

/* Custom scrollbar styles for Firefox */

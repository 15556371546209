.TopImage{
     height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("../../Assets/images/RegisterImage.jpg"); */
    background-image: url('../../Assets/images/BackImage.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
}
.RegisterHeaderCol{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.RegisterHeader{
    /* margin-left: 30px; */
    background-color: var(--color-green);
    width: fit-content;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 30px;
    color: white;
}
.UploadRegisterImageCol{
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.UploadRegisterImage label{
    cursor: pointer;
}
.UploadRegisterImage{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #009FE3;
}

.UploadRegisterImage svg{
    color: white;
    width: 25px;
    height: 25px;
    position: relative;
    top: 35px;  
}
.FormInputReg{
    height: 35px !important;
    font-size: 15px;
}
.FormLabelReg{
    top: 5px;
}

.FormInputColReg{
    margin: 5px !important;
  
}
.MobCol{
    display: flex;
    justify-content: center;
    align-items: center;
}
.MobCol input{
    width: 100%;
}
.MobCol .FormInputCol {
    width: 84%;
}
.MobExt{
    width: 15%;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 30px;
    background-color: var(--color-pink);
    color: white;
    font-size: 14px;
    margin-right: 5px;
}
#MobileNo{
    padding: 0;
    margin: 0 !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


#country{
    padding-left:15%;
}
.Option{
    padding: 0 !important;
    left: 0;
    color: #009FE3 !important;
    border-radius: 30px !important;
}
.MobCol .FormInputCol {
    width: 100%;
    padding: 0;
}
.AddressCol{
    margin: 5px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

}
.RegisterForm form{
    justify-content: space-between !important;
}
.FormInputColReg{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.NurseryDetails{
    margin-top: 20px !important;
}
.NurseryDetailsCol{
    color: var(--color-blue);
}
.NurseryDetailsCol label{
    color: var(--color-blue);
}
.NurseryDetailsCol input{
    border-color: var(--color-blue);
}
.NurseryDetailsCol input:focus{
    border-color: #009fe3c4 !important;
}

.StartFeesCol input{
    padding-left: 100px !important;
}
.StartFeesCol label{
    left: 30px !important;
}
.ClassesNoCol input{
    padding-left: 120px !important;
}
.ClassesNoCol label{
    left: 30px !important;
}
.ChildrenNoCol input{
    padding-left: 120px !important;
}
.ChildrenNoCol label{
    left: 30px !important;
}
.EmployeeNumbers label{
    left: 30px;
}
.EmployeeNumbers input{
    padding-left: 130px;
}
.ProvidedServicesCol label{
    left: 30px;
}
.ProvidedServicesCol input{
    padding-left: 160px;
}
.AboutNurseryCol label{
    left: 30px;
}
.AboutNurseryCol input{
    padding-left: 140px;
}
.AcceptCol{
    padding-left: 15px !important;
    margin-top: 30px !important;
    margin-bottom:30px !important;
}
.AcceptCol label{
    color: black;
}
.Note{
    color: var(--color-pink);
}
.NoteText{
    background-color: var(--color-pink);
    color: white;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 5px;


    text-align: start;
    border-radius: 30px;
}
.RegisterBtns{
    margin-top: 30px !important;
   display: flex;
   align-items: center;
   justify-content: end;
}
.RegisterBtn{
    background-color: var(--color-pink);
    border: none;
    outline: none;
    color: white;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    font-weight:800 ;
    border-radius: 20px;
    margin-right: 10px;
}
.CancelBtn{
    background-color:#F6E8E8;
    border: none;
    outline: none;
    color: var(--color-pink);
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    font-weight:500 ;
    border-radius: 20px;
}

.NurseryNumberRegister{
    display: flex;
    justify-content: flex-end;
    align-items: baseline !important;
}
.EmpNoRegisterInput{
    padding-left: 180px !important;
}
.NurseryNumberRegister input{
    padding-left: 110px !important;
}

@media (max-width:1400px) {

    #Branches{
        padding-left: 28% !important;
    }
    #StartFees{
        padding-left: 22% !important;
    }
    #ClassesNo{
        padding-left: 24% !important;
    }
    #ChildrenNo{
        padding-left: 25% !important;
    }
    #EmployeeNo{
        padding-left: 28% !important;
        
    }
    #ProvidedServices{     
        padding-left: 14% !important;
    }

}

@media (max-width:1199px) {

    .FormInputColReg #Email{
        padding-left: 75px;
    }
    #MobileNo{
        padding-left: 125px;

    }

    .AddressCol #Address{
        padding-left:100px !important;

    }
    #Branches{
        padding-left: 130px !important;
    }
    #StartFees{
        padding-left: 110px !important;
    }
    #ClassesNo{
        padding-left: 120px !important;
    }
    #ChildrenNo{
        padding-left: 130px !important;
    }
    #EmployeeNo{
        padding-left: 140px !important;
        
    }
    #ProvidedServices{     
        padding-left: 170px !important;
    }

    #AboutNursery{
        padding-left: 140px !important;

    }

}
@media (max-width:768px) {

    .MobExt{
        font-size: 12px;
    }
}

.NurseryName input{
    padding-left: 130px !important;
}
.NurseryName label{
    left: 30px !important;
}
.EmailRegisterCol input{
    padding-left: 70px;
}
.EmailRegisterCol label{
    left: 30px !important;
}
.AddressCol label{
    left: 30px;
}
.AddressCol input{
    padding-left: 90px;
}
.BranchesCol label{
    left: 30px;
}
.BranchesCol input{
    padding-left: 130px;
}
.MobileNoLabel{
    left: 15px !important;
}
.linkNursery{
    text-decoration: none;
}
.CityProfile label{
    left: 30px;
}
.CityProfile input{
    padding-left: 55px;
}
.NurseryImage{
    display: flex;
    justify-content: center;
    align-items: center;
}
.NurseryImage svg{
    width: 80px !important;
    height: 100px;
    font-size: larger;
}
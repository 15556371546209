.PolicyHeader{
    text-align: start;
    font-size: 18px;
    font-weight: 700;
    color: var(--color-pink);
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.PolicyDescription{
    text-align: start;
    padding-bottom: 10px
}
.DescriptionPolicy{
    min-height: 150px;
    padding-top: 5px;
}
.PolicyHeader svg{
    width: 15px;
    height:15px;
}   
.DeletePolicy{
    cursor: pointer;
}
.Tag{
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    display: inline-block;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s;
}
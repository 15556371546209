.TableContainer {
    width: 100%;
}

.TableContainer table {
    width: 100%;
    border-spacing: 0;
}

.TableContainerEmployees td {
    background-color: var(--color-pink) !important;
    color: white !important;
    padding: 10px;
    border: none;
    width: calc(100%/3);
}
.TableContainer td input[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid white;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
}

.TableContainer input[type="checkbox"]::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-pink); /* Background color before checked */
    border-radius: 4px;
}

.TableContainer input[type="checkbox"]:checked::before {
    background-color: var(--color-pink); /* Background color when checked */
}

.TableContainer input[type="checkbox"]:checked::after {
    content: "✓";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 10px;
    font-weight: 900;
}
.TableContainer tr {
    margin-bottom: 10px; /* Adjust the value to increase/decrease the margin */
    display: table-row; /* Resetting the display property */
}

.TableContainer tr td:nth-child(1) {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

.TableContainerEmployees tr td:nth-child(3) {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}


/* New styles to add margin between rows */
.TableContainer tr {
    display: block;
    margin-bottom: 10px; /* Adjust the value to increase/decrease the margin */
}

.TableContainerEmployees tr td {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    position: relative;
    height: 46px;
}
.NamePayment{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}
.AvatarTd span{
    display: flex;
    align-items: center;
}

.SelectAll{
    display: flex;
    justify-content: end;
    align-items: center;
}
.InputSelectAll{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.InputSelectAll{
    font-size: 14px;
    padding: 5px;
}
.Payed svg{
    color: var(--color-green);
}
.SearchPayment{
    margin-right: 5px;
    width: 50%;
}

.EmpName{
    display: flex;
    align-items: center;
}
.AvatarCol{
    width: 10% !important;
}
.NameCol{
    width: 80% !important;
}
.avatar{
    min-width: 30px;
    min-height: 30px;
    background-color: var(--color-blue);
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
}
.RecordEmpTable{
    background-color: var(--color-pink);
    color: white;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
}
.TableContainerEmployees{
    display: flex;
    justify-content: center;
}
.TableContainerEmployees>.container{
    margin-left: 10px !important;
    margin-right: 10px !important;
    min-width: 97% !important;
}

.FormContainer{
    background-color: white;
    max-height:100%  !important;
    overflow: visible;
}
.AddTeacherRow{
    justify-content: space-between !important;
}
.EmpFormCol{
    position: relative;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

}
.EmpInput{
    border: none;
    border: 2px solid var(--color-pink);
    height: 35px !important;
    border-radius: 30px;
    width: 100%;
    outline: none;
}
.EmpLabel{
    position: absolute;
    left: 20px;
    top: 4px;
    color: var(--color-pink);
    font-weight: 600;
}
.EmpNameInput{
    padding-left: 60px;
}
.EmpJobTitleInput{
    padding-left: 75px;
}
.EmpEmailInput{
    padding-left: 60px;
}
.EmpAddressInput{
    padding-left: 80px;
}
.FormEmpCol{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.EgEmpMob{
    width: 100px;
    color: white;
    background-color: var(--color-pink);
    border-radius: 30px;
    height: 35px;
    margin-right: 5px;
    font-size: 14px;

}
.EmpMobFormCol{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.EmpMobLabel{
    left: 110px;
}
.EmpMobInput{
    padding-left: 110px;
}
.TeacherManagement{
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: var(--color-pink);
    color: white;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    font-weight: 600;
    font-size: 16px;
}
@media (max-width:1600px) {
    .EmpMobLabel{
        left:100px;
    }
    .EmpMobInput{
        padding-left: 115px;
    }
}
@media (max-width:1250px) {
    .EmpMobLabel{
        left:100px;
    }
    .EmpMobInput{
        padding-left: 110px;
    }
}
@media (max-width:990px) {
    .EmpMobLabel{
        left:110px;
    }
    .EmpMobInput{
        padding-left: 110px;
    }
}
@media (max-width:1062px) {
    .TableContainer .NamePayment::after {
        content: attr(data-content);
        position: absolute;
        top: -100%;
        left: 0;
        border-radius: 15px;
        padding: 5px;
        background-color: var(--color-pink);
    }
    
    .TableContainer .NamePayment:hover::after ,
    .TableContainer .NamePayment:hover td{
        top: 2px;
        overflow: visible;
        z-index: 10;
        padding: 5px;
      

    }
    td:hover  {
        overflow: visible;
    }
}
@media (max-width:480px) {
    .EmpMobLabel{
        left:90px;
    }
    .EmpMobInput{
        padding-left: 100px;
    }
}
@media (max-width:360px) {
    .EmpMobLabel{
        left:90px;
    }
    .EmpMobInput{
        padding-left: 110px;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  
} */
.toggle-button{
  background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  top: 30px;
  left: 30px;
}
:root {
  --color-pink: #ED6D91;
  --color-blue: #009FE3;
  --color-green: #AED065;
  --color-light-blue:#F2FAFE;
  --color-light-pink:#ed6d911a;
  
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.Center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.App {
  display: flex;
}

.content {
  flex-grow: 1;
  /* padding: 20px; */
  padding-top: 0;
  transition: margin-left 0.3s;
  width: 80%;
}

.content.shifted {
  margin-left: 250px !important;
}

.toggle-button {
  display: none;
  left: 30px;
  top: 32px;
}

@media (max-width: 768px) {
  .toggle-button {
    display: inline-block;
    color: var(--color-pink);
  }
  .toggle-button svg{
    width: 20px;
    height: 20px;
  }
  .content.shifted {
    margin-left: 0;
  }
  .SearchCol{
    left: 50px;
  }

  
}


@media (min-width: 768px) {
  .Shift{
    padding-left: 250px;
  }

  
}


.UploadRegisterImage{
  overflow: hidden;
}
/* Loader */

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  
}
.loader::before,
.loader::after {    
  content:"";
  grid-area: 1/1;
  --c:no-repeat radial-gradient(farthest-side,#009FE3 92%,#009FE3);
  background: 
    var(--c) 50%  0, 
    var(--c) 50%  100%, 
    var(--c) 100% 50%, 
    var(--c) 0    50%;
  background-size: 12px 12px;
  animation: l12 1s infinite;
}
.loader::before {
  margin: 4px;
  filter: hue-rotate(45deg);
  background-size: 8px 8px;
  animation-timing-function: linear
}

@keyframes l12 { 
  100%{transform: rotate(.5turn)}
}
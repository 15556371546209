.otp-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom:50px;
  }
  
  .otp-input {
    width: 60px;
    height:60px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .otp-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
  .ResendCode{
    margin-left: 10px;
    cursor: pointer;
  }
  .BackToLogin {
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .BackToLogin svg{
    margin-right: 5px;
  }
  .ConfirmPassword{
    padding-left: 165px !important;
  }
  .Toaster{
    z-index: 10000;
    position: absolute;
    top: 100px;
    left: 100px;
  }
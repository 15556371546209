.sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-blue);
    color: white;
    transition: transform 0.3s;
    transform: translateX(-100%);
    z-index: 998;
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
    max-height: 432px;
    overflow-y: scroll;
    margin-right: 5px;
  }
  
  /* Custom scrollbar styles */
  .sidebar ul::-webkit-scrollbar {
    width: 2px;
  }
  
  .sidebar ul::-webkit-scrollbar-thumb {
    background-color: white;
  }
  
  .sidebar ul::-webkit-scrollbar-track {
    background: transparent;
  }
  


  .sidebar li {
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: start;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .sidebar li a{
    text-align: start;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .sidebar li svg {
    padding: 10px;
  }
  .Logout{
    cursor: pointer;
  }
  
  .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  header{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .HeaderContainer{
    padding: 10px !important;
    margin: 0 !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .HeaderRow{
    width: 100% !important;
  }
  .SearchCol input{
    height: 35px !important;
    font-size: 14px;
    padding-left: 20px;
    color: grey;
    position: relative;
    /* left: 20px; */
  }
  .SearchCol{
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  /* .SearchCol svg{
    position: absolute;
    right: 30px;
    top: 14px;
    color: var(--color-pink);
  } */
  .RightSideHeader{
    display: flex;
    justify-content: end;
    align-items: center;

  }
  .RightSideHeader svg{
    /* margin: 10px; */
    padding: 10px;
    width: 20px;
    height: 20px;
    color: var(--color-pink);
    cursor: pointer;
    transition: all 0.1s linear;
  }
  .RightSideHeader svg:hover{
    color: var(--color-blue);
  }
  .SideBarImage{
    margin-top: 20px;
    margin-bottom: 20px;

  }
  .SideImage{
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 50%;
  }
  .SideBarHr{
    position: relative;
    width: 90%;
    height: 1px;
    left: 5%;
    background-color: white;
  }
  .WelcomeText{
    font-weight: 700;
    font-size: 25px;
    color: #1e1e1e;    
  }
  .HeaderName{
    white-space: nowrap;
  }
  .HeaderContainer{
    position: relative;
  }
.Notifications {
  position: absolute;
  width: 320px;
  height: 400px;
  overflow-y: scroll;
  background-color: white;
  z-index: 999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  right: 20px;

}

/* Custom Scrollbar Styling */
.Notifications::-webkit-scrollbar {
  width: 1px; /* Scrollbar width */
}

.Notifications::-webkit-scrollbar-thumb {
  background-color: #1e1e1e; 
  border-radius: 10px; 
}

.Notifications::-webkit-scrollbar-track {
  background-color: transparent; 
}


.NotificationItem{
  padding:10px !important;
  margin: 10px;
  background-color: #eee;
  border-radius: 25px;
  text-align: start;
}
.NotificationText{
  padding:10px;
  padding-top: 0px;
  font-size: 15px;
}
.NotificationItemTitleAndTime{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NotificationItemTitle{
  margin: 5px;
  margin-right: 10px ;
  font-size: 15px;
  font-weight: 700;
}
.NotificationItemTime{
  font-size: 12px;
  padding: 10px;

}
.Notify{
  position: relative;
}

.NotificationCount{
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height:20px;
  background-color: var(--color-pink);
  color: white;
  border-radius: 50%;
  padding: 10px;
  font-size: 9px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}


  @media (min-width: 769px) {
    .sidebar {
      transform: translateX(0);
    }
    
  
    .close-button {
      display: none;
    }
  }
  @media (max-width:768px) {
    .WelcomeText{
      font-size: 18px;
    }
  }
  @media (max-width:380px) {
    .WelcomeText{
      font-size: 16px;
    }
  }
  
.HomeCard {
    display: flex;
    align-items: center;
    background-color: #00aaff;
    color: white;
    padding: 20px;
    border-radius: 10px;
    /* max-width: 500px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px;
    height: 150px;
  }
  
  .circle-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;
    min-width: 50px;
}
  
  .progress-circle {
    width: 100%;
    height: 100%;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke: #a1c15e;
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.6s ease 0s;
  }
  
  .percentage {
    font-size: 0.5em;
    text-anchor: middle;
    fill: #ff007f;
    fill: white;
  }
  
  .card-content {
    flex-grow: 1;
    text-align: start;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .view-link {
    cursor: pointer;
    text-decoration: underline;
    margin-left: 20px;
    padding-bottom: 5px;
  }
  
  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .trend-chart {
    width: 100px;
    height: 20px;
  }
  .PinkCard{
    background-color: var(--color-pink);
  }
  .ChartsRow .col-lg-4{
    /* padding: 20px; */
    /* padding-left: 5px; */
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .ChartItem{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    border-radius: 15px;
    
  }
  .ChartItem>span{
    font-weight: 600;
  }
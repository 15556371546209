
.NurseryProfileTopPart{
    background-image: url("../../Assets/images/NurseryProfileImageTop.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 60px;
    border-bottom-left-radius:30px;
    border-bottom-right-radius: 30px;
    min-height: 270px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.NurseryProfileImage{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.NurseryReviewImage{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    min-height: 70px;
}
.NurseryReviewImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.NurseryProfileImage{
    /* background-color: white; */
}
.NurseryProfileImage img{
    /* width: 70px !important; */
    object-fit: cover;
}

.NurseryProfileTopPartCol{
    margin-top: 10px !important;
    padding: 10px;
    flex-direction: column;
}
.NurseryProfileTitle{
    margin-top: 10px;
    font-size: 16px;
    color: #00FFFF;
}
.NurseryRating{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
}
.NurseryRating svg{
    color: #FFD300;
}
.RatingNumber{
    color: var(--color-pink);
    font-weight: 500;
    margin-left: 2px;
    font-size: 14px;
}
.RatingNurseryCol{
    display: flex;
    justify-content: space-between;
}
.NurseryProfileRatingCol{
    display: flex;
    align-items: center;
    width: calc(100%/2 - 100px);
    margin-top: 20px !important;
    margin-bottom:20px !important;
}
.NurseryRatingText{
    margin-top: auto;
    margin-bottom: auto;
}
.NurseryProfileLine{
    height: 1px;
    width: 100%;
    background-color: #00FFFF;
}
.NuseryProfileTabbing{
    background-color: var(--color-pink);
    color: white;
    margin-top: 20px !important;
    border-radius: 50px !important;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}
.NuseryProfileTabbing span{
    cursor: pointer;
}
.NuseryProfileTabbingCol{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.NurseryContainer{
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 20px;
}
.NurseryGallery{
    background-color: white;
    min-height: 120px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
}
.AddGallery{
    border-bottom: 2px solid #00FFFF;
    padding: 10px;
}
.AddGallery span {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}
.GalleryItem{
    height: 200px;
    margin: 20px !important;
    min-width: 200px;
    position: relative;
}
.GalleryImage{
    background-color: #ddd;
    width: 100%;
    height: 100%;
    border-radius: 35px ;
    display: flex;
    overflow: hidden;
}
.GalleryImage img{
    object-fit: cover;
}
.AlbumName{
    margin-top:5px ;
    margin-bottom: 5px;
}

.GalleryBackBtn{
    position: absolute;
    top: 3px;
    left: 5px;
}
.ReviewItem{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 20px !important;
    margin-top: 10px;
    padding: 20px;
}
.ReviewsContainer{
    padding-top: 10px;
}
.NurseryReviewImage{
    border: 1px solid black;
}
.ReviewImageAndHeader{
    display: flex;
    width: 100%;
}
.ReviewTitle{
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-blue);
    width: 100%;
}
.ReviewItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.ReviewText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    max-width: 80%;
    width: 100%;
}
.ReviewBody{
    text-align: start;
}
.ReviewStars{
    color: #FFD300;
}
.ContactInfo ul{
    padding: 10px;
    text-align: start;
}
.ProvidedServices{
    text-align: start;
}
.ProvidedServices ul li{
    margin: 10px;

}
.ContactInfo ul li{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
}
.ContactInfo ul li svg{
    color: var(--color-blue);
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.HeaderInfo{
    box-shadow: rgba(50, 50, 93, 0.25) 1px 0px 3px 0px;
    text-align: center;
}
.AboutInfoText{
    padding: 20px ;
    padding-top: 10px;
    text-align: start;
}
.ProvidedServices ul li svg{
    width: 15px !important;
    height: 15px !important;
}
.SubscribtionHeader{
    color: var(--color-pink);
    text-align: start;
    padding: 10px;
   
}
.SubscribtionHeader{
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.SubscriptionItem .Title{
    padding-left: 20px !important;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight:500 ;
}

.SubscriptionItem .Data{
    padding-right: 20px !important;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight:500 ;
}


.SubscriptionItem{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #009fe337;
}
.SubscriptionItem:nth-child(4){

    border-bottom: none;
}
.PaymentNurseryItem{
    padding-bottom: 5px !important;
}
.NuseryPaymentHistory{
    padding: 20px;
}
.ProfileActive{
    border-bottom: 1px solid;
}
.EditAlbumName{
    margin-left: 10px;
    display: inline;
    cursor: pointer;
}
.UploadImage{
    cursor: pointer;
}
@media (max-width:486px) {
    .NuseryProfileTabbing{
        font-size: 14px;
        font-weight: 600;
    }
    .ReviewItem{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .ReviewImageAndHeader {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
    .ReviewText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 80%;
    }
    .ReviewBody {
        text-align: center;
    }
    .ReviewStars {
        margin-top: 15px;
    }
}
.DeleteGallery{
    position: absolute;
    z-index: 10;
    top: 20px;
    right:30px;
    color: var(--color-pink);
    cursor: pointer;
}

.HeartBlackOutlined svg{
    color: black;
}
.EditNurseryProfileBtn{
    color: white;
    position: absolute;
    cursor: pointer;
    top: 20px;
    right:20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.15s linear;
}
.EditNurseryProfileBtn:hover{
    background: white;
    color: black;
}



/* Modal */
.EditProfileModal{
    background-color: rgba(0, 0, 0, 0.463);
}

.modal-header{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}


.modal-body{
    width: 100%;
}
.EditProfileModal> div{
    text-align: start;
}
.EditProfileModalLabel{
    padding-left: 10px;
    font-weight: 600;
}
.ProfilePreview{
    border-radius: 50% !important;
    min-height: 150px;
    min-width: 150px;

}
.EditProfilePreview label{
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Cam{
    position: absolute !important;
    z-index: 8;
}
.Cam svg{
    width: 25px;
    height:25px;
}
.EditProfileModalContent{
    border-radius: 25px !important;
}
.AddAnotherPhone span{
    cursor: pointer;
}
.EditProfileButtons button{
    width: auto !important;
    padding-left: 20px;
    padding-right:20px;
    margin: 10px;
}
.EditProfileButtons{
    width: 100%;
    display: flex;
    justify-content: center;
}
.ChartsRow{
    margin-top: 40px !important;
    display: flex;
    justify-content: space-between;
}
.PieChartCol{
    padding: 50px;
    padding-top: 0;
}
.ChartsRow h5{
    text-align: start;
}



.PieChartCol{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.UploadRegisterImage label{
    height: 100% !important;
}
.UploadRegisterImage label img{
    height: 100%;
}
.Pending{
    background-color:var(--color-blue);
}
.Rejected{
    background-color: var(--color-pink);
}
.Accepted{
    background-color: var(--color-green);
}
.MealsContainer{
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.MealItem:nth-child(1){
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.MealItem:nth-child(3){
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.MealItem{
    border: 2px solid var(--color-pink);
    padding: 10px;
    cursor: pointer;
    filter: opacity(0.5);
    background-color: rgba(128, 128, 128, 0.651);
    transition: all 0.1s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.MealInput{
    width: 100% !important;
}
.MealItem img{
    max-width: 100px;
}
.ActiveMeal{
    filter: opacity(1);
    background-color: white;

}
.MealInputMainCol{
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: row-reverse;
    margin-top: 10px !important;
}
.MealInputMainCol label{
    font-weight: 700;
    padding-right: 10px;
    color: #514B66;
    width: 50px;
    text-align: start;
}
.MealInputMainCol input{
    height: 50px;
}
.SaveMealCol{
    display: flex;
    justify-content: end;
    align-items: center;
}
.SaveMealCol .RegisterBtn{
    margin-right: 0 !important;
}
@media (max-width:340px) {
    .MealItem h5{
        font-size: 18px !important;
    }
}

.MealCol{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.MealCol .MealInputMainCol{
    width: calc(100% - 60px);
}
.DeleteMeal{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border: 2px solid;
    border-radius: 10px;
    background: #ed6d91;
    color: white;
}
.MealTypeHeader{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* Overlay.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
 
 
  .mymodal {
    background: white;
    width: 350px;
    max-width: 90%;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    overflow: hidden;
    border: 2px solid var(--color-pink);
}
.FormHr{
    height: 2px;
    background-color: var(--color-pink);
    width: 150%;
    margin-bottom: 20px;
    margin-top: 8px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-class-form {
    display: flex;
    flex-direction: column;
    width: 90%;
}
.form-buttons{
    display: flex;
    justify-content: space-between;
}


.ClassNameInput{
    border: 2px solid var(--color-pink);
    width: 100%;
    /* height: 50px; */
    border-radius: 10px;
    transition: all 0.1s linear;
    padding:2px;
    padding-left: 10px;
    padding-right: 10px;
}
.Age{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Age label{
    font-weight: 600;
}
.Age input{
    width:calc(100%/3) ;
    margin: 10px;
    border: 1px solid var(--color-pink);
    padding: 2px;
    border-radius: 10px;
    padding-left: 10px;
    
}
.Age input:focus ,
.ClassNameInput:focus{
    outline: none;
}
.addSubjectForm label{
    margin-bottom: 20px;

}
.form-buttons button{
    width: calc(100%/2.5);
    padding-left: 0;
    padding-right: 0;
   
}
.NewsletterDescription{
    background-color: var(--color-light-blue);  
    min-height: 100px;
    border: none;
    border-radius: 20px;
    padding: 15px;
}
.AttachImage{
    background-color: var(--color-light-pink);
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.AttachImage svg{
    color: var(--color-pink);
    cursor: pointer;

}
.AttachImage span{
    color: #A4A4A4;
    padding-left: 5px;
    cursor: pointer;
}
.ConfirmDelete{
    margin-bottom: 20px;
    color: #7D7D7D;
}
.PopUpValidation{
    display: flex;
    text-align: start;
    width: 100%;
    padding-left: 10px;
    
}
.PopUpError{
    text-align: start;
    margin-left: 10px;
}
.HasContainer{
    display: flex;
    margin-bottom: 5px;
}
.HasContainer input[type='checkbox']{
    margin:5px; 
}